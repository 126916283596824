<template>
	<div class="cs-block cs-landing-page-block">
		<div class="cs-base-block">
			<div class="container">
				<CSLandingPage :data="data" />
			</div>
		</div>
	</div>
</template>

<script>
import {computed} from '../libs/common-fn';

export default {
	name: "LandingPage",
	components: {
		CSLandingPage: () => import("../components/CSLandingPage.vue"),
	},
	props: {
		data: {
			type: Object,
			default: () => {},
		},
	},
	computed: computed('LandingPage')
}
</script>
<style scoped lang="scss">
@import "../styles/main.scss";
.cs-landing-page-block {
    background-color: $bg_color;
}
::v-deep {
    a.download {
        cursor: pointer;
        border-radius: 0 !important;
        svg {
            max-width: 20% !important;
            max-height: 20% !important;
        }
    }
}
</style>
